import axios from 'axios';
import {ServerResponseError, BadRequestError} from '@/shared/errors';

const {VUE_APP_API_URL} = process.env;

export default function (path = '') {
  const url = `${VUE_APP_API_URL}${path}`;

  return {
    path,
    url,

    get(params = {}) {
      return axios.get(url, {params})
        .catch(err => {
          if (String(err.message).includes(400)) {
            throw new BadRequestError(err.message);
          }
          throw new ServerResponseError(err.message);
        });
    },

    post(data = {}) {
      return axios.post(url, data, {
        headers: {'Content-Type': 'application/json'}
      });
    },

    put(data = {}) {
      return axios.put(url, data);
    },

    delete(params = {}) {
      return axios.delete(url, {params});
    }
  };
}
