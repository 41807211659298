function ServerResponseError(message) {
  this.name = 'ServerResponseError';
  this.message = message;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  }
}

function BadRequestError(message) {
  this.name = 'BadRequestError';
  this.message = message;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  }
}

export {
  ServerResponseError,
  BadRequestError
};
  